import type { CustomFlowbiteTheme } from 'flowbite-react';
import {
  button,
  dropdown,
  datepicker,
  spinner,
  tabs,
  popover,
  table,
  toggleSwitch,
  alert,
  modal,
  accordion,
} from './components';

const theme: CustomFlowbiteTheme = {
  label: {
    root: {
      base: 'text-sm font-medium',
      disabled: 'opacity-50',
      colors: {
        default: 'text-black',
        primary: 'text-primary',
        failure: 'text-failure',
        warning: 'text-warning',
        success: 'text-success',
      },
    },
  },
  textarea: {
    base: 'block w-full rounded border border-1 disabled:cursor-not-allowed disabled:opacity-50 text-sm',
    colors: {
      primary:
        'border-black text-black focus:border-primary focus:ring-primary',
      secondary:
        'border-grey bg-grey enabled:hover:bg-grey text-black focus:border-primary focus:ring-primary',
      failure:
        'border-failure bg-failure text-failure placeholder-failure focus:border-failure focus:ring-failure',
      warning:
        'border-warning bg-warning text-warning placeholder-warning focus:border-warning focus:ring-warning',
      success:
        'border-success bg-success text-success placeholder-success focus:border-success focus:ring-success',
    },
    withShadow: {
      on: 'shadow-sm',
      off: '',
    },
  },
  select: {
    field: {
      select: {
        base: '!rounded border w-full disabled:bg-transparent disabled:cursor-not-allowed disabled:opacity-50',
        colors: {
          primary:
            'border-black bg-white text-black placeholder-grey focus:border-primary focus:ring-primary',
          failure:
            'border-failure bg-failure text-failure placeholder-failure focus:border-failure focus:ring-failure',
          warning:
            'border-warning bg-warning text-warning placeholder-warning focus:border-warning focus:ring-warning',
          success:
            'border-success bg-success text-success placeholder-success focus:border-success focus:ring-success',
        },
      },
    },
  },
  dropdown,
  spinner,
  datepicker,
  checkbox: {
    root: {
      base: 'h-4 w-4 rounded focus:ring-2 border border-gray-300 dark:border-gray-600 dark:bg-gray-700 bg-gray-100',
      color: {
        default: 'focus:ring-primary text-primary',
        failure: 'focus:ring-failure text-failure',
        success: 'focus:ring-success text-success',
        warning: 'focus:ring-yellow text-yellow',
      },
    },
  },
  floatingLabel: {
    input: {
      default: {
        filled: {
          sm: 'peer block w-full appearance-none rounded border-0 border-b-2 border-bg-black bg-grey px-2.5 pb-2.5 pt-5 text-xs text-black focus:border-primary focus:outline-none focus:ring-0',
          md: 'peer block w-full appearance-none rounded border-0 border-b-2 border-bg-black bg-grey px-2.5 pb-2.5 pt-5 text-sm text-black focus:border-primary focus:outline-none focus:ring-0',
        },
        outlined: {
          sm: 'border-1 peer block w-full appearance-none rounded border-bg-black bg-transparent px-2.5 pb-2.5 pt-4 text-xs text-black focus:border-primary focus:outline-none focus:ring-0',
          md: 'border-1 peer block w-full appearance-none rounded border-bg-black bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-black focus:border-primary focus:outline-none focus:ring-0',
        },
        standard: {
          sm: 'block py-2.5 px-0 w-full text-xs text-black bg-transparent border-0 border-b-2 border-bg-black appearance-none focus:outline-none focus:ring-0 focus:border-primary peer',
          md: 'block py-2.5 px-0 w-full text-sm text-black bg-transparent border-0 border-b-2 border-bg-black appearance-none focus:outline-none focus:ring-0 focus:border-primary peer',
        },
      },
      success: {
        filled: {
          sm: 'block rounded px-2.5 pb-2.5 pt-5 w-full text-xs text-black bg-grey border-0 border-b-2 border-success appearance-none focus:outline-none focus:ring-0 focus:border-success peer',
          md: 'block rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-black bg-grey border-0 border-b-2 border-success appearance-none focus:outline-none focus:ring-0 focus:border-success peer',
        },
        outlined: {
          sm: 'block px-2.5 pb-2.5 pt-4 w-full text-xs text-black bg-transparent rounded border-1 border-success appearance-none focus:outline-none focus:ring-0 focus:border-success peer',
          md: 'block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent rounded border-1 border-success appearance-none focus:outline-none focus:ring-0 focus:border-success peer',
        },
        standard: {
          sm: 'block py-2.5 px-0 w-full text-xs text-black bg-transparent border-0 border-b-2 border-success appearance-none focus:outline-none focus:ring-0 focus:border-success peer',
          md: 'block py-2.5 px-0 w-full text-sm text-black bg-transparent border-0 border-b-2 border-success appearance-none focus:outline-none focus:ring-0 focus:border-success peer',
        },
      },
      error: {
        filled: {
          sm: 'block rounded px-2.5 pb-2.5 pt-5 w-full text-xs text-black bg-grey border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 border-error focus:border-error peer',
          md: 'block rounded px-2.5 pb-2.5 pt-5 w-full text-sm text-black bg-grey border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 border-error focus:border-error peer',
        },
        outlined: {
          sm: 'block px-2.5 pb-2.5 pt-4 w-full text-xs text-black bg-transparent rounded border-1 appearance-none border-error focus:outline-none focus:ring-0 focus:border-error peer',
          md: 'block px-2.5 pb-2.5 pt-4 w-full text-sm text-black bg-transparent rounded border-1 appearance-none border-error focus:outline-none focus:ring-0 focus:border-error peer',
        },
        standard: {
          sm: 'block py-2.5 px-0 w-full text-xs text-black bg-transparent border-0 border-b-2 border-error appearance-none focus:outline-none focus:ring-0 focus:border-error peer',
          md: 'block py-2.5 px-0 w-full text-sm text-black bg-transparent border-0 border-b-2 border-error appearance-none focus:outline-none focus:ring-0 focus:border-error peer',
        },
      },
    },
    label: {
      default: {
        filled: {
          sm: 'absolute left-2.5 top-4 z-10 origin-[0] -translate-y-4 scale-75 transition-transform text-xs text-black  duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-primary ',
          md: 'absolute left-2.5 top-4 z-10 origin-[0] -translate-y-4 scale-75 transition-transform text-sm text-black duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-primary ',
        },
        outlined: {
          sm: 'absolute left-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 transition-transform bg-white px-2 text-xs text-black duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-primary ',
          md: 'absolute left-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 transition-transform bg-white px-2 text-sm text-black duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-primary ',
        },
        standard: {
          sm: 'absolute text-xs text-black  transition-transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] duration-300 peer-focus:left-0 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6',
          md: 'absolute text-sm text-black  transition-transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] duration-300 peer-focus:left-0 peer-focus:text-primary  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6',
        },
      },
      success: {
        filled: {
          sm: 'absolute left-2.5 top-4 z-10 origin-[0] -translate-y-4 scale-75 transition-transform text-sm text-success duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-focus:scale-75',
          md: 'absolute left-2.5 top-4 z-10 origin-[0] -translate-y-4 scale-75 transition-transform text-sm text-success duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-focus:scale-75',
        },
        outlined: {
          sm: 'absolute left-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 transition-transform bg-white px-2 text-sm text-success duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2',
          md: 'absolute left-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 transition-transform bg-white px-2 text-sm text-success duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2',
        },
        standard: {
          sm: 'absolute text-xs text-success  transition-transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] duration-300 peer-focus:left-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6',
          md: 'absolute text-sm text-success  transition-transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] duration-300 peer-focus:left-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6',
        },
      },
      error: {
        filled: {
          sm: 'absolute left-2.5 top-4 z-10 origin-[0] -translate-y-4 scale-75 transition-transform text-xs text-failure duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-focus:scale-75',
          md: 'absolute left-2.5 top-4 z-10 origin-[0] -translate-y-4 scale-75 transition-transform text-xs text-failure duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-focus:scale-75',
        },
        outlined: {
          sm: 'absolute left-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 transition-transform bg-white px-2 text-xs text-failure duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2',
          md: 'absolute left-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 transition-transform bg-white px-2 text-xs text-failure duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2',
        },
        standard: {
          sm: 'absolute text-xs text-failure transition-transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] duration-300 peer-focus:left-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6',
          md: 'absolute text-sm text-failure transition-transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] duration-300 peer-focus:left-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6',
        },
      },
    },
    helperText: {
      default: 'mt-2 text-xs text-grey',
      success: 'mt-2 text-xs text-success',
      error: 'mt-2 text-xs text-failure',
    },
  },
  textInput: {
    base: 'flex w-full',
    addon:
      'inline-flex items-center rounded-l border border-r-0 border-black bg-grey-darker px-3 w-fit',
    field: {
      base: 'flex flex-grow',
      input: {
        base: 'disabled:bg-transparent w-full disabled:cursor-not-allowed disabled:opacity-50',
        colors: {
          primary:
            'w-full border-black bg-white text-black placeholder-grey focus:border-primary focus:ring-primary text-black placeholder-black-lighter',
          secondary:
            'border-transparent bg-grey-lighter text-black focus:border-primary focus:ring-transparent',
          transparent:
            'border-transparent bg-transparent border-none border-grey-darker text-black text-center focus:border-primary focus:ring-transparent',
          failure:
            'border-failure text-failure placeholder-failure focus:border-failure focus:ring-failure',
          warning:
            'border-warning bg-warning text-warning placeholder-warning focus:border-warning focus:ring-warning',
          success:
            'border-success bg-success text-success placeholder-success focus:border-success focus:ring-success',
        },
        withAddon: {
          on: 'rounded-r border-l-0',
          off: 'rounded border',
        },
        sizes: {
          xl: 'p-6 text-xl',
        },
      },
    },
  },
  navbar: {
    root: {
      base: 'bg-white px-2 sm:px-4 py-2.5',
      inner: {
        base: 'w-full flex items-center justify-between',
        fluid: {
          on: '',
          off: 'container',
        },
      },
    },
    collapse: {
      base: 'w-full',
    },
  },
  button,
  tabs,
  popover,
  table,
  toggleSwitch,
  alert,
  modal,
  accordion,
};

export { theme };
