import debounce from 'lodash.debounce';
import { useEffect, useLayoutEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

export const isAppleOS = (): boolean => {
  const ua = navigator.userAgent;
  return /iPad|iPhone|iPod|Macintosh/.test(ua) && navigator.maxTouchPoints > 1;
};

export const useScreenSize = () => {
  const [isSmall, setIsSmall] = useState(false);
  const [isMedium, setIsMedium] = useState(false);
  const [isLarge, setIsLarge] = useState(false);
  const [isXLarge, setIsXLarge] = useState(false);
  const [is2XLarge, setIs2XLarge] = useState(false);

  const [isOnlySmall, setIsOnlySmall] = useState(false);
  const [isOnlyMedium, setIsOnlyMedium] = useState(false);
  const [isOnlyLarge, setIsOnlyLarge] = useState(false);
  const [isOnlyXLarge, setIsOnlyXLarge] = useState(false);
  const [isOnly2XLarge, setIsOnly2XLarge] = useState(false);

  const [isLandscape, setIsLandscape] = useState(
    window.innerWidth > window.innerHeight,
  );
  const [isPortrait, setIsPortrait] = useState(
    window.innerHeight >= window.innerWidth,
  );

  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
      setIsPortrait(window.innerHeight >= window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useLayoutEffect(() => {
    const updateSize = (): void => {
      setIsOnlySmall(window.innerWidth < 768);
      setIsOnlyMedium(window.innerWidth >= 768 && window.innerWidth < 1024);
      setIsOnlyLarge(window.innerWidth >= 1024 && window.innerWidth < 1280);
      setIsOnlyXLarge(window.innerWidth >= 1280 && window.innerWidth < 1536);
      setIsOnly2XLarge(window.innerWidth >= 1536);

      setIsSmall(window.innerWidth < 768);
      setIsMedium(window.innerWidth < 1024);
      setIsLarge(window.innerWidth < 1280);
      setIsXLarge(window.innerWidth < 1536);
      setIs2XLarge(window.innerWidth >= 1536);
    };
    window.addEventListener('resize', debounce(updateSize, 250));
    updateSize();
    return (): void => window.removeEventListener('resize', updateSize);
  }, []);

  return {
    isSmall,
    isMedium,
    isLarge,
    isXLarge,
    is2XLarge,
    isOnlySmall,
    isOnlyMedium,
    isOnlyLarge,
    isOnlyXLarge,
    isOnly2XLarge,
    isMobile,
    isLandscape,
    isPortrait,
  };
};
