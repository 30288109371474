import { createContext, useContext } from 'react';
import { Self } from '~/generated/models/Self';

export type AuthContextType = {
  self: Self | null;
  requiresSignup: boolean;
  fetchingSelf: boolean;
  isAuthenticated: boolean;
  isLogged: boolean;
  isHost: boolean;
  fetchSelf: () => Promise<void>;
  login: (appState?: Record<string, unknown>) => void;
  logout: (states?: Record<string, unknown>) => void;
  hasFlag: (flag: string) => boolean;
  hasRole: (role: string) => boolean;
};

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <AuthProvider>.');
};

export const AuthContext = createContext<AuthContextType>({
  self: null,
  requiresSignup: false,
  isLogged: false,
  fetchingSelf: false,
  isAuthenticated: false,
  isHost: false,
  fetchSelf: stub,
  login: stub,
  logout: stub,
  hasFlag: stub,
  hasRole: stub,
});

export function useAuth() {
  return useContext(AuthContext);
}
